import React from "react";
import PageTitle from "../Shared/PageTitle";
import Banner from "./Banner";
import Coverage from "./Coverage";
import SendEmail from "./SendEmail";

const Contact = () => {
  return (
    <section>
      <PageTitle title="Contact"></PageTitle>
      <Banner></Banner>
      <Coverage></Coverage>
      <SendEmail></SendEmail>
    </section>
  );
};

export default Contact;
