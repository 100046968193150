const SystemAdminData = [
  {
    name: "Rahul Pal",
    designation: "System Admin",
    phone: "01627-111089",
    img: "https://i.ibb.co/HHkqR2j/Whats-App-Image-2023-07-21-at-14-27-32.jpg",
  },
];

export default SystemAdminData;
