import React from "react";
import PageTitle from "../Shared/PageTitle";
import FTPData from "../../Data/FTPData/FTPData";

const FTPServer = () => {
  return (
    <section className="bg-slate-800">
      <PageTitle title="FTP Server"></PageTitle>
      <div
        data-aos="fade-up"
        data-aos-delay="400"
        data-aos-duration="3000"
        className="text-white px-6 py-10"
      >
        <h1 className="text-5xl font-bold">
          Watch Unlimited <span className="text-red-600">HD Movies</span> <br />
          <span className="text-red-600">Live TV</span> Free
        </h1>
      </div>
      <div
        data-aos="fade-up"
        data-aos-delay="450"
        data-aos-duration="3000"
        className="grid grid-cols-1 lg:grid-cols-4 gap-4 px-4 pb-20 pt-8"
      >
        {FTPData.map((item, i) => (
          <div className="card lg:max-w-lg bg-base-100 shadow-xl rounded-none">
            <figure className="">
              <img src={item.img} alt="Shoes" className="rounded-none" />
            </figure>
            <a
              href={item.link}
              target="_blank"
              rel="noreferrer"
              className="btn bg-red-700 text-white border-none rounded-none"
            >
              {item.btn}
            </a>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FTPServer;
