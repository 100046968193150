import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";

const FTP = () => {
  return (
    <section className="bg-slate-800">
      <div
        data-aos="fade-up"
        data-aos-delay="400"
        data-aos-duration="3000"
        className="text-white px-6 py-10"
      >
        <h1 className="text-5xl font-bold">
          Watch Unlimited <span className="text-red-600">HD Movies</span> <br />
          <span className="text-red-600">Live TV</span> Free
        </h1>
      </div>

      <div
        data-aos="fade-up"
        data-aos-delay="450"
        data-aos-duration="3000"
        className="grid grid-cols-1 lg:grid-cols-4 gap-4 px-4 pb-20 pt-8"
      >
        {/* Link-1 */}
        <div className="card lg:max-w-lg bg-base-100 shadow-xl rounded-none">
          <figure className="">
            <img
              src="https://i.ibb.co/XLv9jM1/JP-Network-BDai.png"
              alt="Shoes"
              className="rounded-none"
            />
          </figure>
          <a
            href="http://103.87.212.46"
            target="_blank"
            rel="noreferrer"
            className="btn bg-red-700 text-white border-none rounded-none"
          >
            Watch Now
          </a>
        </div>
        {/* Link-2 */}
        <div
          data-aos="fade-up"
          data-aos-delay="500"
          data-aos-duration="3000"
          className="card lg:max-w-lg bg-base-100 shadow-xl rounded-none"
        >
          <figure className="">
            <img
              src="https://i.ibb.co/XLv9jM1/JP-Network-BDai.png"
              alt="Shoes"
              className="rounded-none"
            />
          </figure>
          <a
            href="http://ftp.alphamediazone.com/"
            target="_blank"
            rel="noreferrer"
            className="btn bg-red-700 text-white border-none rounded-none"
          >
            Watch Now
          </a>
        </div>
        {/* Link-3 */}
        <div
          data-aos="fade-up"
          data-aos-delay="550"
          data-aos-duration="3000"
          className="card lg:max-w-lg bg-base-100 shadow-xl rounded-none"
        >
          <figure className="">
            <img
              src="https://i.ibb.co/XLv9jM1/JP-Network-BDai.png"
              alt="Shoes"
              className="rounded-none"
            />
          </figure>
          <a
            href="http://play.alphamediazone.com/"
            target="_blank"
            rel="noreferrer"
            className="btn bg-red-700 text-white border-none rounded-none"
          >
            Watch Now
          </a>
        </div>
        {/* Link-4 */}
        <div
          data-aos="fade-up"
          data-aos-delay="600"
          data-aos-duration="3000"
          className="card lg:max-w-lg bg-base-100 shadow-xl rounded-none"
        >
          <figure className="">
            <img
              src="https://i.ibb.co/XLv9jM1/JP-Network-BDai.png"
              alt="Shoes"
              className="rounded-none"
            />
          </figure>
          <a
            href="http://movies.alphamediazone.com/"
            target="_blank"
            rel="noreferrer"
            className="btn bg-red-700 text-white border-none rounded-none"
          >
            Watch Now
          </a>
        </div>
      </div>
      <div className="text-white pb-10 flex justify-center items-center">
        <Link to="/server" className="text-xl">
          See More{" "}
        </Link>
        <AiOutlineArrowRight className="text-xl mt-1"></AiOutlineArrowRight>
      </div>
    </section>
  );
};

export default FTP;
