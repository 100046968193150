import React from "react";
import "./payment.css";

const PaymentMain = () => {
  return (
    <div className="container mx-auto">
      <div className="payment-main">
        <div className="payment-heading mt-20 mb-6">
          <h4
            data-aos="fade-down"
            data-aos-delay="500"
            data-aos-duration="1300"
            className="text-center font-bold text-4xl"
          >
            Payment Via <span>bKash</span>
          </h4>
        </div>
        <div className="hero-image">
          <img
            data-aos="fade-down"
            data-aos-delay="600"
            data-aos-duration="1300"
            src="https://i.ibb.co/NjDPZ6Y/payment-process.jpg"
            alt="background"
            className="img-fluid"
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentMain;
