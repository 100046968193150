import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';
import { MdTouchApp } from 'react-icons/md';
import ContactInfo from './ContactInfo';

const SendEmail = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_fz5qorm', 'template_0kgwsu3', form.current, '7txVVMo7AhH_LOxDb')
            .then((result) => {
                console.log(result.text);
                toast.success("Message Sent");
            }, (error) => {
                console.log(error.text);
                toast.error("Something is wrong")
            });
        form.value = " ";
    };
    return (
        <div className='bg-gray-800'>
            <h1 className='text-4xl font-bold text-center text-white pt-14'>Contact with Us</h1>
            <div className="hero min-h-screen my-20">
                <div className="hero-content flex-col lg:flex-row-reverse">
                    <div className="text-center lg:text-left lg:ml-10  text-white">
                        <h1 className="text-5xl font-bold flex">Get <span className='text-red-600 mx-2'>In</span> Touch <MdTouchApp></MdTouchApp></h1>
                        <h1 className="text-xl font-bold mt-6">If you want a new connection or need any services. Feel free to contact us.</h1>
                        <p className="py-6 text-md">We are always active in response. Just leave a message, and we'll reply within 2 hours. Or call us.</p>
                        <ContactInfo></ContactInfo>
                    </div>

                    <form ref={form} onSubmit={sendEmail} className="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
                        <h1 className='text-center text-black text-xl font-bold mt-4'>Send a Message</h1>
                        <div className="card-body">
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Your Name</span>
                                </label>
                                <input type="text" placeholder="Full name" className="input input-bordered" name='user_name' required />
                            </div>
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Your Email</span>
                                </label>
                                <input type="email" placeholder="Email address (Optional)" className="input input-bordered" name='user_email' />
                            </div>

                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Phone</span>
                                </label>
                                <input type="text" placeholder="Phone Number" className="input input-bordered" name='user_phone' required />
                            </div>

                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Message</span>
                                </label>
                                <textarea
                                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                    id="exampleFormControlTextarea1"
                                    rows="3"
                                    placeholder="Your message"
                                    name='message'
                                ></textarea>
                            </div>
                            <div className="form-control mt-6">
                                <button className="btn btn-outline">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    );
};

export default SendEmail;