/* eslint-disable react/style-prop-object */
import React from "react";

const Coverage = () => {
  return (
    <>
      <div className="container mx-auto">
        <div className="rounded-2xl border-red-600 my-14">
          <h1 className="text-4xl text-center text-black rounded-2xl border-red-600	 font-bold">
            Our Coverage <span className="text-red-600">Area</span>
          </h1>
          <p className="text-center text-black">
            Discover our coverage areas and ability in Dhaka city.
          </p>
        </div>

        <div className=" w-full flex justify-center">
          <div className="w-full">
            <iframe
              className="w-full lg:max-w-4xl md:max-w-2xl sm:max-w-xl"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3651.7915239325293!2d90.41228271429706!3d23.75481259452622!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b9a8c3d704e5%3A0xe16bcd296e9ced80!2s32%2C%201%20Malibagh%20Chowdhury%20Para%20Rd%2C%20Dhaka%201219!5e0!3m2!1sen!2sbd!4v1675065735862!5m2!1sen!2sbd"
              width="620"
              height="550"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="google map"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
};

export default Coverage;
