import React from "react";
import SystemAdmin from "./SystemAdmin";
import Fiberteam from "./Fiberteam";
import "./style.css";
import TechnicalTeam from "./TechnicalTeam";
import AccountTeam from "./AccountTeam";

const Team = () => {
  return (
    <section>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="mx-auto mb-10 lg:max-w-xl text-center">
          <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-400">
            THOSE OF US WHO WORK TOGETHER
          </p>
          <h1 className="text-4xl font-bold text-black mb-4">OUR TEAM</h1>
          <p className="text-base text-gray-700 md:text-md">
            Teamwork is the ability to work together toward a common vision. The
            ability to direct individual accomplishments toward organizational
            objectives. It is the fuel that allows common people to attain
            uncommon results.
          </p>
        </div>

        <SystemAdmin />
        <Fiberteam />
        <AccountTeam />
        <TechnicalTeam />
      </div>
    </section>
  );
};

export default Team;
