const FTPData = [
  {
    id: 1,
    link: "http://10.16.100.244",
    img: "https://i.ibb.co/XLv9jM1/JP-Network-BDai.png",
    btn: "Watch Now",
  },
  {
    id: 2,
    link: "http://103.87.212.46",
    img: "https://i.ibb.co/XLv9jM1/JP-Network-BDai.png",
    btn: "Watch Now",
  },
  {
    id: 3,
    link: "http://mm.towkai.com",
    img: "https://i.ibb.co/XLv9jM1/JP-Network-BDai.png",
    btn: "Watch Now",
  },
  {
    id: 4,
    link: "http://dflix.live/",
    img: "https://i.ibb.co/XLv9jM1/JP-Network-BDai.png",
    btn: "Watch Now",
  },
  {
    id: 5,
    link: "http://movies.alphamediazone.com/",
    img: "https://i.ibb.co/XLv9jM1/JP-Network-BDai.png",
    btn: "Watch Now",
  },
  {
    id: 6,
    link: "http://play.alphamediazone.com/",
    img: "https://i.ibb.co/XLv9jM1/JP-Network-BDai.png",
    btn: "Watch Now",
  },
  {
    id: 7,
    link: "http://172.16.50.4",
    img: "https://i.ibb.co/XLv9jM1/JP-Network-BDai.png",
    btn: "Watch Now",
  },
  {
    id: 8,
    link: "http://172.16.50.2",
    img: "https://i.ibb.co/XLv9jM1/JP-Network-BDai.png",
    btn: "Watch Now",
  },
  {
    id: 9,
    link: "http://circleftp.net/",
    img: "https://i.ibb.co/XLv9jM1/JP-Network-BDai.png",
    btn: "Watch Now",
  },
  {
    id: 10,
    link: "http://172.16.50.5",
    img: "https://i.ibb.co/XLv9jM1/JP-Network-BDai.png",
    btn: "Watch Now",
  },
  {
    id: 11,
    link: "http://172.27.27.200",
    img: "https://i.ibb.co/XLv9jM1/JP-Network-BDai.png",
    btn: "Watch Now",
  },
  {
    id: 12,
    link: "http://172.27.27.84",
    img: "https://i.ibb.co/XLv9jM1/JP-Network-BDai.png",
    btn: "Watch Now",
  },
];

export default FTPData;
