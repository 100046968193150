import React from 'react';

const Reviews = () => {
    return (
        <div className='container mx-auto'>
            <div className='my-28'>
                <h2 className='text-xl text-red-600 font-bold text-center'>Testimonials</h2>
                <h1 className='text-5xl text-black font-bold text-center pb-28'>What our clients say?</h1>
                <div className="carousel w-full">
                    <div id="slide1" className="carousel-item relative items-center justify-center w-full flex">
                        <div className="card lg:max-w-lg bg-base-100 shadow-2xl rounded-3xl">
                            <figure className="px-10 pt-10">
                                <div className="avatar">
                                    <div className="w-24 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                                        <img src="https://api.lorem.space/image/face?hash=3174" alt='user' />
                                    </div>
                                </div>
                            </figure>
                            <div className="card-body items-center text-center">
                                <h2 className="card-title">Leo Chan</h2>
                                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt facere expedita earum dolorem natus, voluptatem cupiditate iure ad itaque voluptatum asperiores adipisci quae ratione perferendis eaque. Molestias repudiandae dolorum cumque..</p>
                                <p><span>Ratings:</span></p>
                            </div>
                        </div>
                        <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                            <a href="#slide4" className="btn btn-circle">❮</a>
                            <a href="#slide2" className="btn btn-circle">❯</a>
                        </div>
                    </div>

                    <div id="slide2" className="carousel-item relative items-center justify-center w-full">
                        <div className="card lg:max-w-lg bg-base-100 shadow-2xl rounded-3xl">
                            <figure className="px-10 pt-10">
                                <div className="avatar">
                                    <div className="w-24 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                                        <img src="https://api.lorem.space/image/face?hash=3174" alt='user' />
                                    </div>
                                </div>
                            </figure>
                            <div className="card-body items-center text-center">
                                <h2 className="card-title">Ema Den</h2>
                                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt facere expedita earum dolorem natus, voluptatem cupiditate iure ad itaque voluptatum asperiores adipisci quae ratione perferendis eaque. Molestias repudiandae dolorum cumque..</p>
                                <p><span>Ratings:</span></p>
                            </div>
                        </div>
                        <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                            <a href="#slide1" className="btn btn-circle">❮</a>
                            <a href="#slide3" className="btn btn-circle">❯</a>
                        </div>
                    </div>


                    <div id="slide3" className="carousel-item items-center justify-center relative w-full">
                        <div className="card lg:max-w-lg bg-base-100 shadow-2xl rounded-3xl">
                            <figure className="px-10 pt-10">
                                <div className="avatar">
                                    <div className="w-24 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                                        <img src="https://api.lorem.space/image/face?hash=3174" alt='user' />
                                    </div>
                                </div>
                            </figure>
                            <div className="card-body items-center text-center">
                                <h2 className="card-title">Leo Chan</h2>
                                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt facere expedita earum dolorem natus, voluptatem cupiditate iure ad itaque voluptatum asperiores adipisci quae ratione perferendis eaque. Molestias repudiandae dolorum cumque..</p>
                                <p><span>Ratings:</span></p>
                            </div>
                        </div>
                        <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                            <a href="#slide2" className="btn btn-circle">❮</a>
                            <a href="#slide4" className="btn btn-circle">❯</a>
                        </div>
                    </div>
                    <div id="slide4" className="carousel-item relative items-center justify-center w-full">
                        <div className="card lg:max-w-lg bg-base-100 shadow-2xl rounded-3xl">
                            <figure className="px-10 pt-10">
                                <div className="avatar">
                                    <div className="w-24 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                                        <img src="https://api.lorem.space/image/face?hash=3174" alt='user' />
                                    </div>
                                </div>
                            </figure>
                            <div className="card-body items-center text-center">
                                <h2 className="card-title">Leo Chan</h2>
                                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt facere expedita earum dolorem natus, voluptatem cupiditate iure ad itaque voluptatum asperiores adipisci quae ratione perferendis eaque. Molestias repudiandae dolorum cumque..</p>
                                <p><span>Ratings:</span></p>
                            </div>
                        </div>
                        <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                            <a href="#slide3" className="btn btn-circle">❮</a>
                            <a href="#slide1" className="btn btn-circle">❯</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Reviews;