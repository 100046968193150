const TechnicalTeamData = [
  {
    name: "Ripon Hawlader",
    img: "https://i.ibb.co/LrfYdHc/assistant-technician.jpg",
    phone: "01757155854",
    designation: "Assistant Technician",
    fb: "",
  },
  {
    name: "Shohag Mia",
    img: "https://i.ibb.co/F4wSnss/shohag-mia.jpg",
    phone: "01600086062",
    designation: "Assistant Technician",
    fb: "",
  },
  {
    name: "Md Shamim Sheikh",
    img: "https://i.ibb.co/Zg5bqKL/technician.jpg",
    phone: "01618950343",
    designation: "Technician",
    fb: "",
  },
  {
    name: "Md Ibrahim Islam Emon",
    img: "https://i.ibb.co/hRwWPFL/Whats-App-Image-2023-12-23-at-22-46-21-3c8bdc32.jpg",
    phone: "01812441900",
    designation: "Technician",
    fb: "",
  },
];

export default TechnicalTeamData;
