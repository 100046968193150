import React from "react";
import HomeTeam from "../../Data/TeamData/HomeTeam";

const Team = () => {
  return (
    <section>
      {/* <div className="text-gray-600 body-font">
                <div className="container px-5 py-24 mx-auto">
                    <div className="flex flex-wrap -m-4">
                        <div
                            data-aos="fade-up"
                            data-aos-delay="600"
                            data-aos-duration="1300"
                            data-aos-anchor-placement="top-bottom"
                            className="lg:w-1/3 lg:mb-0 mb-6 p-4">
                            <div className="h-full text-center">
                                <img alt="testimonial" className="w-40 h-40 mb-2 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100" src="https://i.ibb.co/zJJGNtj/Chairman.jpg" /> <br />
                                <span className="inline-block h-1 w-24 rounded bg-red-600 mt-6 mb-4"></span>
                                <h2 className="text-gray-900 font-medium title-font tracking-wider text-sm">MD ABID KHAN PUSON</h2>
                                <p className="text-gray-500">Chairman</p>
                            </div>
                        </div>
                        <div
                            data-aos="fade-up"
                            data-aos-delay="700"
                            data-aos-duration="1400"
                            data-aos-anchor-placement="top-bottom"
                            className="lg:w-1/3 lg:mb-0 mb-6 p-4">
                            <div className="h-full text-center">
                                <img alt="testimonial" className="w-40 h-40 mb-2 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100" src="https://i.ibb.co/cC82Ftw/Managing-director.jpg" /> <br />
                                <span className="inline-block h-1 w-24 rounded bg-green-600 mt-6 mb-4"></span>
                                <h2 className="text-gray-900 font-medium title-font tracking-wider text-sm">MD POLASH KHAN</h2>
                                <p className="text-gray-500">Managing Director</p>
                            </div>
                        </div>
                        <div
                            data-aos="fade-up"
                            data-aos-delay="800"
                            data-aos-duration="1500"
                            data-aos-anchor-placement="top-bottom"
                            className="lg:w-1/3 lg:mb-0 mb-6 p-4">
                            <div className="h-full text-center">
                                <img alt="testimonial" className="w-40 h-40 mb-2 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100" src="https://i.ibb.co/8XdCbsz/Manager.jpg" /> <br />
                                <span className="inline-block h-1 w-24 rounded bg-indigo-600 mt-6 mb-4"></span>
                                <h2 className="text-gray-900 font-medium title-font tracking-wider text-sm">JOLY BEGUM</h2>
                                <p className="text-gray-500">Manager</p>
                            </div>
                        </div>


                    </div>
                </div>
            </div> */}

      <div class="container flex justify-center mx-auto pt-16">
        <div>
          <p class=" text-red-600  pt-20 text-lg text-center font-normal pb-3">
            BUILDING TEAM
          </p>
          <h1 class="tracking-wider rounded-full bg-accent-400 xl:text-4xl text-3xl text-center text-gray-800 font-bold pb-6 sm:w-4/6 w-5/6 mx-auto">
            The Talented People Behind the Scenes of the Organization
          </h1>
        </div>
      </div>
      <div class="w-full bg-gray-100 dark:bg-gray-800 px-10 pt-10">
        <div class="container mx-auto">
          <div
            role="list"
            aria-label="Behind the scenes People "
            class="lg:flex md:flex sm:flex items-center xl:justify-between flex-wrap md:justify-around sm:justify-around lg:justify-around"
          >
            {HomeTeam.map((item, i) => (
              <div
                data-aos="fade-up"
                data-aos-delay="600"
                data-aos-duration="1300"
                data-aos-anchor-placement="top-bottom"
                role="listitem"
                class="xl:w-1/3 sm:w-3/4 md:w-2/5 relative mt-16 mb-32 sm:mb-24 xl:max-w-sm lg:w-2/5"
              >
                <div class="rounded overflow-hidden shadow-md bg-white dark:bg-gray-900">
                  <div class="absolute -mt-20 w-full flex justify-center">
                    <div class="h-32 w-32">
                      <img
                        src={item.photoURL}
                        alt="Display of the Person"
                        className="rounded-full object-cover h-full w-full shadow-md"
                      />
                    </div>
                  </div>
                  <div class="px-6 mt-16">
                    <h1 class="font-bold dark:text-white text-3xl text-center mb-1">
                      {item.name}
                    </h1>
                    <p class="text-gray-800 dark:text-white text-sm text-center">
                      {item.title}
                    </p>
                    <p class="text-center text-gray-600 dark:text-gray-200 text-base pt-3 font-normal">
                      The CEO's role in raising a company's corporate IQ is to
                      establish an atmosphere that promotes knowledge sharing
                      and collaboration.
                    </p>
                    <div class="w-full flex justify-center pt-5 pb-5">
                      <a href="javascript:void(0)" class="mx-5">
                        <div aria-label="Github" role="img">
                          <img
                            src="https://tuk-cdn.s3.amazonaws.com/can-uploader/gray-bg-with-description-svg1.svg"
                            alt="github"
                          />
                        </div>
                      </a>
                      <a href="javascript:void(0)" class="mx-5">
                        <div aria-label="Twitter" role="img">
                          <img
                            src="https://tuk-cdn.s3.amazonaws.com/can-uploader/gray-bg-with-description-svg2.svg"
                            alt="twitter"
                          />
                        </div>
                      </a>
                      <a href="javascript:void(0)" class="mx-5">
                        <div aria-label="Instagram" role="img">
                          <img
                            src="https://tuk-cdn.s3.amazonaws.com/can-uploader/gray-bg-with-description-svg3.svg"
                            alt="instagram"
                          />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
