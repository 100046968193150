import React from "react";
import Banner from "./Banner";
import ChooseUs from "./ChooseUs";
import FTP from "./FTP";
import LiveSupport from "./LiveSupport";
import Reviews from "./Reviews";
import SendEmail from "./SendEmail";
import Services from "./Services";
import Work from "./Work";
import PageTitle from "../Shared/PageTitle";
import Team from "./Team";
import Packages from "./Packages02";

const Home = () => {
  return (
    <>
      <PageTitle title="Home"></PageTitle>
      <Banner></Banner>
      <LiveSupport></LiveSupport>
      <ChooseUs></ChooseUs>
      <Packages></Packages>
      <Services></Services>
      <SendEmail></SendEmail>
      <Work></Work>
      <FTP></FTP>
      <Reviews></Reviews>
      <Team></Team>
    </>
  );
};

export default Home;
