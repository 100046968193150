import React from 'react';
import { FaLocationArrow } from "react-icons/fa";
import { AiTwotoneMail } from "react-icons/ai";
import { IoMdCall } from "react-icons/io";

const ContactInfo = () => {
    return (
        <div className='grid grid-cols-1 lg:grid-cols-3 gap-5'>
            <div
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="3000"
                className="card lg:max-w-lg bg-base-100 shadow-xl">
                <figure>
                    <FaLocationArrow className='text-red-600 font-bold text-5xl mt-4'></FaLocationArrow>
                </figure>
                <div className="card-body items-center text-center text-black">
                    <h2 className="card-title">Office Address</h2>
                    <p>32/1/A,Malibagh Chowdhury Para, Dhaka-1217</p>
                </div>
            </div>
            <div
                data-aos="fade-up"
                data-aos-delay="500"
                data-aos-duration="3000"
                className="card lg:max-w-lg bg-base-100 shadow-xl">
                <figure>
                    <IoMdCall className='text-red-600 font-bold text-5xl mt-4'></IoMdCall>
                </figure>
                <div className="card-body items-center text-center text-black">
                    <h2 className="card-title">Phones</h2>
                    <p>01818883222, 01681553230</p>
                </div>
            </div>
            <div
                data-aos="fade-up"
                data-aos-delay="600"
                data-aos-duration="3000"
                className="card lg:max-w-lg bg-base-100 shadow-xl">
                <figure>
                    <AiTwotoneMail className='text-red-600 font-bold text-5xl mt-4'></AiTwotoneMail>
                </figure>
                <div className="card-body items-center text-center text-black">
                    <h2 className="card-title">Email Address</h2>
                    <p>jpnetworkbd@gmail.com</p>
                </div>
            </div>
        </div>
    );
};

export default ContactInfo;