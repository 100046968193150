import React from "react";
import { Link } from "react-router-dom";
import PageTitle from "../Shared/PageTitle";
import { AiOutlineArrowRight } from "react-icons/ai";
import { TbCurrencyTaka } from "react-icons/tb";

const Packages = () => {
  return (
    <section className="bg-white">
      <PageTitle title="Packages"></PageTitle>
      <div className="text-center py-10">
        <h4 className="text-red-600 font-bold text-2xl">Tariffs</h4>
        <h1 className="text-4xl font-bold text-black">CHOOSE YOUR PLAN</h1>
      </div>
      <div class="grid gap-6 md:grid-cols-2 lg:grid-cols-3 sm:mx-auto container">
        <div
          data-aos="fade-up-left"
          data-aos-delay="500"
          data-aos-duration="1500"
        >
          <div class="p-8 bg-gray-900 rounded">
            <div class="mb-4 text-center">
              <p class="text-xl font-medium tracking-wide text-white">
                Starter Plan
              </p>
              <p class="text-md font-normal tracking-wide text-white">
                Internet Bandwidth
              </p>
              <div class="flex items-center justify-center my-4">
                <p class="mr-2 text-3xl font-semibold text-orange-400 lg:text-5xl">
                  8 <span className="lg:text-4xl text-gray-400">Mbps</span>
                </p>
              </div>
              <div class="flex items-center justify-center">
                <p class="mr-2 text-5xl font-semibold text-white lg:text-5xl">
                  <span className="flex">
                    500
                    <TbCurrencyTaka className="text-4xl -mr-3 -ml-1" />
                  </span>
                </p>
                <p class="text-lg text-gray-400">/ month</p>
              </div>
            </div>
            <ul class="mb-8 space-y-2">
              <li class="flex items-center">
                <div class="mr-3">
                  <svg
                    class="w-4 h-4 text-teal-400"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeWidth="2"
                  >
                    <polyline
                      fill="none"
                      stroke="currentColor"
                      points="6,12 10,16 18,8"
                    />
                    <circle
                      cx="12"
                      cy="12"
                      fill="none"
                      r="11"
                      stroke="currentColor"
                    />
                  </svg>
                </div>
                <p class="font-medium text-gray-300">
                  BDIX Bandwidth (Unlimited)
                </p>
              </li>
              <li class="flex items-center">
                <div class="mr-3">
                  <svg
                    class="w-4 h-4 text-teal-400"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeWidth="2"
                  >
                    <polyline
                      fill="none"
                      stroke="currentColor"
                      points="6,12 10,16 18,8"
                    />
                    <circle
                      cx="12"
                      cy="12"
                      fill="none"
                      r="11"
                      stroke="currentColor"
                    />
                  </svg>
                </div>
                <p class="font-medium text-gray-300">
                  Youtube & Facebook (Unlimited)
                </p>
              </li>
              <li class="flex items-center">
                <div class="mr-3">
                  <svg
                    class="w-4 h-4 text-teal-400"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeWidth="2"
                  >
                    <polyline
                      fill="none"
                      stroke="currentColor"
                      points="6,12 10,16 18,8"
                    />
                    <circle
                      cx="12"
                      cy="12"
                      fill="none"
                      r="11"
                      stroke="currentColor"
                    />
                  </svg>
                </div>
                <p class="font-medium text-gray-300">
                  24/7 Phone and Online Support
                </p>
              </li>
            </ul>
            <Link
              to="/contact"
              type="submit"
              class="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-teal-500 transition duration-200 rounded shadow-md bg-gray-800 hover:bg-teal-600 hover:text-white focus:shadow-outline focus:outline-none"
            >
              Contact Now
            </Link>
          </div>
          <div class="w-11/12 h-2 mx-auto bg-gray-900 rounded-b opacity-75" />
          <div class="w-10/12 h-2 mx-auto bg-gray-900 rounded-b opacity-50" />
          <div class="w-9/12 h-2 mx-auto bg-gray-900 rounded-b opacity-25" />
        </div>
        <div
          data-aos="fade-down-left"
          data-aos-delay="600"
          data-aos-duration="1500"
        >
          <div class="p-8 bg-gray-900 rounded">
            <div class="mb-4 text-center">
              <p class="text-xl font-medium tracking-wide text-white">
                Starter Plan
              </p>
              <p class="text-md font-normal tracking-wide text-white">
                Internet Bandwidth
              </p>
              <div class="flex items-center justify-center my-4">
                <p class="mr-2 text-3xl font-semibold text-orange-400 lg:text-5xl">
                  16 <span className="lg:text-4xl text-gray-400">Mbps</span>
                </p>
              </div>
              <div class="flex items-center justify-center">
                <p class="mr-2 text-5xl font-semibold text-white lg:text-5xl">
                  <span className="flex">
                    1000
                    <TbCurrencyTaka className="text-4xl -mr-3 -ml-1" />
                  </span>
                </p>
                <p class="text-lg text-gray-400">/ month</p>
              </div>
            </div>
            <ul class="mb-8 space-y-2">
              <li class="flex items-center">
                <div class="mr-3">
                  <svg
                    class="w-4 h-4 text-teal-400"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeWidth="2"
                  >
                    <polyline
                      fill="none"
                      stroke="currentColor"
                      points="6,12 10,16 18,8"
                    />
                    <circle
                      cx="12"
                      cy="12"
                      fill="none"
                      r="11"
                      stroke="currentColor"
                    />
                  </svg>
                </div>
                <p class="font-medium text-gray-300">
                  BDIX Bandwidth (Unlimited)
                </p>
              </li>
              <li class="flex items-center">
                <div class="mr-3">
                  <svg
                    class="w-4 h-4 text-teal-400"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeWidth="2"
                  >
                    <polyline
                      fill="none"
                      stroke="currentColor"
                      points="6,12 10,16 18,8"
                    />
                    <circle
                      cx="12"
                      cy="12"
                      fill="none"
                      r="11"
                      stroke="currentColor"
                    />
                  </svg>
                </div>
                <p class="font-medium text-gray-300">
                  Youtube & Facebook (Unlimited)
                </p>
              </li>
              <li class="flex items-center">
                <div class="mr-3">
                  <svg
                    class="w-4 h-4 text-teal-400"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeWidth="2"
                  >
                    <polyline
                      fill="none"
                      stroke="currentColor"
                      points="6,12 10,16 18,8"
                    />
                    <circle
                      cx="12"
                      cy="12"
                      fill="none"
                      r="11"
                      stroke="currentColor"
                    />
                  </svg>
                </div>
                <p class="font-medium text-gray-300">
                  24/7 Phone and Online Support
                </p>
              </li>
            </ul>
            <Link
              to="/contact"
              type="submit"
              class="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-teal-500 transition duration-200 rounded shadow-md bg-gray-800 hover:bg-teal-600 hover:text-white focus:shadow-outline focus:outline-none"
            >
              Contact Now
            </Link>
          </div>
          <div class="w-11/12 h-2 mx-auto bg-gray-900 rounded-b opacity-75" />
          <div class="w-10/12 h-2 mx-auto bg-gray-900 rounded-b opacity-50" />
          <div class="w-9/12 h-2 mx-auto bg-gray-900 rounded-b opacity-25" />
        </div>
        <div
          data-aos="fade-down-right"
          data-aos-delay="700"
          data-aos-duration="1500"
        >
          <div class="p-8 bg-gray-900 rounded">
            <div class="mb-4 text-center">
              <p class="text-xl font-medium tracking-wide text-white">
                Pro Plan
              </p>
              <p class="text-md font-normal tracking-wide text-white">
                Internet Bandwidth
              </p>
              <div class="flex items-center justify-center my-4">
                <p class="mr-2 text-3xl font-semibold text-orange-400 lg:text-5xl">
                  20 <span className="lg:text-4xl text-gray-400">Mbps</span>
                </p>
              </div>
              <div class="flex items-center justify-center">
                <p class="mr-2 text-5xl font-semibold text-white lg:text-5xl">
                  <span className="flex">
                    1200
                    <TbCurrencyTaka className="text-4xl -mr-3 -ml-1" />
                  </span>
                </p>
                <p class="text-lg text-gray-400">/ month</p>
              </div>
            </div>
            <ul class="mb-8 space-y-2">
              <li class="flex items-center">
                <div class="mr-3">
                  <svg
                    class="w-4 h-4 text-teal-400"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeWidth="2"
                  >
                    <polyline
                      fill="none"
                      stroke="currentColor"
                      points="6,12 10,16 18,8"
                    />
                    <circle
                      cx="12"
                      cy="12"
                      fill="none"
                      r="11"
                      stroke="currentColor"
                    />
                  </svg>
                </div>
                <p class="font-medium text-gray-300">
                  BDIX Bandwidth (Unlimited)
                </p>
              </li>
              <li class="flex items-center">
                <div class="mr-3">
                  <svg
                    class="w-4 h-4 text-teal-400"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeWidth="2"
                  >
                    <polyline
                      fill="none"
                      stroke="currentColor"
                      points="6,12 10,16 18,8"
                    />
                    <circle
                      cx="12"
                      cy="12"
                      fill="none"
                      r="11"
                      stroke="currentColor"
                    />
                  </svg>
                </div>
                <p class="font-medium text-gray-300">
                  Youtube & Facebook (Unlimited)
                </p>
              </li>
              <li class="flex items-center">
                <div class="mr-3">
                  <svg
                    class="w-4 h-4 text-teal-400"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeWidth="2"
                  >
                    <polyline
                      fill="none"
                      stroke="currentColor"
                      points="6,12 10,16 18,8"
                    />
                    <circle
                      cx="12"
                      cy="12"
                      fill="none"
                      r="11"
                      stroke="currentColor"
                    />
                  </svg>
                </div>
                <p class="font-medium text-gray-300">
                  24/7 Phone and Online Support
                </p>
              </li>
            </ul>
            <Link
              to="/contact"
              type="submit"
              class="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-teal-500 transition duration-200 rounded shadow-md bg-gray-800 hover:bg-teal-600 hover:text-white focus:shadow-outline focus:outline-none"
            >
              Contact Now
            </Link>
          </div>
          <div class="w-11/12 h-2 mx-auto bg-gray-900 rounded-b opacity-75" />
          <div class="w-10/12 h-2 mx-auto bg-gray-900 rounded-b opacity-50" />
          <div class="w-9/12 h-2 mx-auto bg-gray-900 rounded-b opacity-25" />
        </div>
      </div>
      <div className="text-black mt-10 pb-10 flex justify-center items-center text-center">
        <Link to="/packages" className="text-lg font-semibold">
          See more{" "}
        </Link>
        <AiOutlineArrowRight className="text-xl mt-1"></AiOutlineArrowRight>
      </div>
    </section>
  );
};

export default Packages;
