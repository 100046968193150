import React from "react";
import PageTitle from "../Shared/PageTitle";
import "./payment.css";
import PaymentMain from "./PaymentMain";

const Payment = () => {
  return (
    <>
      <PageTitle title="Pay Bill"></PageTitle>
      <div className="payment">
        <div
          className="hero hero-main"
          style={{
            backgroundImage: "url(https://i.ibb.co/2Z5MJVk/payment.webp)",
            objectFit: "cover",
          }}
        >
          <div className="hero-overlay bg-opacity-20"></div>
          <div className="hero-content text-center text-neutral-content">
            <div className="max-w-lg">
              <h1
                data-aos="fade-down"
                data-aos-delay="400"
                data-aos-duration="1300"
                className="mb-5 text-5xl font-bold capitalize"
              >
                Make your payment
              </h1>
              <p
                data-aos="fade-down"
                data-aos-delay="400"
                data-aos-duration="1300"
                className="mb-5"
              >
                Provident cupiditate voluptatem et in. Quaerat fugiat ut
                assumenda excepturi exercitationem quasi. In deleniti eaque aut
                repudiandae et a id nisi.
              </p>
            </div>
          </div>
        </div>
        <PaymentMain />
      </div>
    </>
  );
};

export default Payment;
