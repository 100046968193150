import React from "react";

const LiveSupport = () => {
  return (
    <>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 mb-20">
        <div className="grid gap-10 lg:grid-cols-2">
          <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
            <div className="max-w-xl mb-6">
              <h1
                data-aos="fade-right"
                data-aos-delay="500"
                data-aos-duration="1200"
                className="text-5xl font-bold text-black"
              >
                <span className="text-yellow-500">Live Sport</span> <br /> &{" "}
                <span className="text-yellow-500">TV-shows</span> for best
                friends!
              </h1>

              <p
                data-aos="fade-right"
                data-aos-delay="600"
                data-aos-duration="1200"
                className="text-base text-gray-700 md:text-lg py-6"
              >
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae. explicabo.
              </p>
              <div
                data-aos="fade-right"
                data-aos-delay="700"
                data-aos-duration="1200"
                className="flex justify-start my-2 text-black"
              >
                <p className="text-lg font-semibold mr-5">
                  <span className="text-2xl text-red-600">4K</span> Ultra HD
                  Quality
                </p>
                <p className="text-lg font-semibold">
                  <span className="text-2xl text-red-600">150+</span> Online
                  Channels
                </p>
              </div>
            </div>
            <div>
              <a
                data-aos="fade-down"
                data-aos-delay="800"
                data-aos-duration="1200"
                href="/"
                aria-label=""
                className="inline-flex items-center justify-center h-12 px-4 font-medium tracking-wide text-white transition duration-200 rounded shadow-md focus:shadow-outline focus:outline-none banner-btn"
              >
                Read More
                <svg
                  className="inline-block w-3 ml-2"
                  fill="currentColor"
                  viewBox="0 0 12 12"
                >
                  <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
                </svg>
              </a>
            </div>
          </div>
          <div className="flex items-center justify-center -mx-4 lg:pl-8">
            <div className="flex flex-col items-end px-3">
              <img
                data-aos="fade-left"
                data-aos-delay="400"
                data-aos-duration="1300"
                className="object-cover mb-6 rounded shadow-lg h-28 sm:h-48 xl:h-56 w-28 sm:w-48 xl:w-56"
                src="https://images.pexels.com/photos/3184287/pexels-photo-3184287.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
                alt=""
              />
              <img
                data-aos="fade-left"
                data-aos-delay="400"
                data-aos-duration="1300"
                className="object-cover w-20 h-20 rounded shadow-lg sm:h-32 xl:h-40 sm:w-32 xl:w-40"
                src="https://images.pexels.com/photos/3182812/pexels-photo-3182812.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
                alt=""
              />
            </div>
            <div className="px-3">
              <img
                data-aos="fade-left"
                data-aos-delay="400"
                data-aos-duration="1300"
                className="object-cover w-40 h-40 rounded shadow-lg sm:h-64 xl:h-80 sm:w-64 xl:w-80"
                src="https://images.pexels.com/photos/3182739/pexels-photo-3182739.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;w=500"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LiveSupport;
