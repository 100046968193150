import React from "react";
import { Link } from "react-router-dom";
import { HiArrowSmRight } from "react-icons/hi";
import "./style.css";

const Banner = () => {
  return (
    <section id="banner">
      <div className="relative flex flex-col-reverse py-12 lg:pt-0 lg:flex-col lg:pb-0">
        <div
          className="inset-y-0 top-0 right-0 z-0 w-full max-w-xl px-4 mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-7/12 lg:max-w-full lg:absolute xl:px-0"
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-delay="1200"
          data-aos-offset="3"
        >
          <svg
            className="absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block"
            viewBox="0 0 100 100"
            fill="currentColor"
            preserveAspectRatio="none slice"
          >
            <path d="M50 0H100L50 100H0L50 0Z" />
          </svg>
          <picture>
            <img
              className="object-cover w-full h-56 rounded shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
              src="https://i.ibb.co/1n6rBxF/main-banner.webp?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
              alt=""
            />
          </picture>
        </div>

        <div className="relative flex flex-col items-start w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:max-w-screen-xl">
          <div className="mb-16 lg:my-40 lg:max-w-lg lg:pr-5">
            <p
              className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-800 uppercase rounded-full bg-teal-400"
              data-aos="fade-down"
              data-aos-delay="600"
              data-aos-duration="1500"
            >
              Brand new
            </p>
            <h2
              className="mb-2 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none"
              data-aos="fade-right"
              data-aos-delay="400"
              data-aos-duration="1200"
            >
              Welcome to
              <br className="hidden md:block" />
              <span
                className="inline-block text-5xl mt-2"
                style={{ color: "#EE9D0F" }}
              >
                JP NETWORK BD
              </span>
            </h2>
            <p
              className="pr-5 mb-5 text-base text-gray-900 md:text-lg lg:text-2xl font-semibold"
              data-aos="fade-left"
              data-aos-delay="600"
              data-aos-duration="1300"
            >
              We Bring for You Unlimited Internet
            </p>
            <p
              className="pr-5 mb-5 text-base text-gray-500 md:text-md"
              data-aos="fade-right"
              data-aos-delay="800"
              data-aos-duration="1300"
            >
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
              quae. explicabo.
            </p>
            <div className="flex items-center">
              <Link
                to="/contact"
                className="inline-flex items-center justify-center h-12 px-4 font-medium tracking-wide text-white transition duration-200 rounded shadow-md focus:shadow-outline focus:outline-none banner-btn"
                data-aos="fade-up"
                data-aos-delay="800"
                data-aos-duration="1200"
              >
                Contact Now{" "}
                <HiArrowSmRight className="text-2xl"></HiArrowSmRight>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
