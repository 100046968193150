const FiberTeamData = [
  {
    name: "Farukh Mia",
    designation: "Fiber Team Leader",
    phone: "01847-102223",
    img: "https://i.ibb.co/kc6GZtL/Fiber-Team-Leader.jpg",
  },
  {
    name: "Unknown",
    designation: "Unknown",
    phone: "none",
    img: "https://i.ibb.co/s9J1Hpx/60111.webp",
  },
];

export default FiberTeamData;
