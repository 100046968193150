const AccountTeamData = [
  {
    name: "Tareq Aziz Tanvir",
    designation: "Accountant",
    phone: "01611-021081",
    img: "https://i.ibb.co/86fGmj4/Accounts-2.jpg",
  },

  {
    name: "Ratin Chowdhury",
    designation: "Accountant",
    phone: "01975-553230",
    img: "https://i.ibb.co/3B36CQj/Accounts.jpg",
  },
];

export default AccountTeamData;
