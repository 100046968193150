import React from 'react';
import PageTitle from '../Shared/PageTitle';
import Team from './Team';
import TeamBanner from './TeamBanner';

const OurTeam = () => {
    return (
        <section>
            <PageTitle title="Our Team"></PageTitle>
            <TeamBanner></TeamBanner>
            <Team></Team>
        </section>
    );
};

export default OurTeam;