const HomeTeam = [
  {
    name: "Md Abid Khan Puson",
    title: "Chairman",
    photoURL:
      "https://i.ibb.co/zJJGNtj/Chairman.jpg?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=550&amp;w=1060",
    facebookURL: "",
    twitterURL: "",
  },
  {
    name: "Md Polash Khan",
    title: "Managing Director",
    photoURL:
      "https://i.ibb.co/Ns2g0HZ/Managing-director.jpg?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260",
    facebookURL: "",
    twitterURL: "",
  },
  {
    name: "Joly Begum",
    title: "Manager",
    photoURL:
      "https://i.ibb.co/8XdCbsz/Manager.jpg?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260",
    facebookURL: "",
    twitterURL: "",
  },
];

export default HomeTeam;
