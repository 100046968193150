import React from "react";
import FiberTeamData from "../../Data/FiberTeamData/FiberTeamData";

const Fiberteam = () => {
  return (
    <>
      <section className="bg-white dark:bg-gray-900 my-12">
        <div className="container px-6 py-10 mx-auto">
          <div className="fiber-team-heading text-center">
            <p className="inline-block px-3 py-px text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-400">
              WHO MAINTAINS THE fiber PART
            </p>
            <h3 className="text-2xl font-semibold text-center text-gray-800 capitalize lg:text-3xl dark:text-white">
              The Exclusive Fiber Team
            </h3>
            <div class="flex justify-center mx-auto mt-6">
              <span class="inline-block w-40 h-1 bg-blue-500 rounded-full"></span>
              <span class="inline-block w-3 h-1 mx-1 bg-blue-500 rounded-full"></span>
              <span class="inline-block w-1 h-1 bg-blue-500 rounded-full"></span>
            </div>

            <p className="max-w-2xl mx-auto my-6 text-center text-gray-500 dark:text-gray-300">
              You can contact with us to know about cable connection.
            </p>
          </div>

          <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-16 md:grid-cols-2 xl:grid-cols-2">
            {FiberTeamData.map((item, i) => (
              <div
                data-aos="fade-up"
                data-aos-delay="700"
                data-aos-duration="1400"
                data-aos-anchor-placement="top-bottom"
                key={i}
                className="flex flex-col items-center p-8 transition-colors duration-300 transform border cursor-pointer rounded-xl hover:border-transparent group hover:bg-blue-600 dark:border-gray-700 dark:hover:border-transparent"
              >
                <img
                  className="object-cover w-32 h-32 rounded-full ring-4 ring-gray-300"
                  src={item.img}
                  alt=""
                />

                <h4 className="mt-4 text-2xl font-semibold text-gray-700 capitalize dark:text-white group-hover:text-white">
                  {item.name}
                </h4>

                <p className="mt-2 text-gray-500 capitalize dark:text-gray-300 group-hover:text-gray-300">
                  {item.designation}
                </p>
                <p className="mt-2 text-gray-500 capitalize dark:text-gray-300 group-hover:text-gray-300">
                  Phone: {item.phone}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Fiberteam;
