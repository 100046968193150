import React from 'react';
import { AiOutlineStar } from "react-icons/ai";

const Work = () => {
    return (
        <section className='lg:flex justify-center py-36'>
            <div
                data-aos="flip-down"
                data-aos-delay="400"
                data-aos-duration="1200"
                className="card w-full bg-red-400 shadow-xl rounded-none">
                <div className="card-body">
                    <h2 className="card-title text-3xl text-black font-bold">Who we are?</h2>
                    <p className='text-white'>Providing Internet, Internet and IPT services through a quality and stable network systems that attain the high standard of security system.</p>
                    <div className='ml-10 text-black'>
                        <p className='flex'><AiOutlineStar className='text-2xl text-white mr-2'></AiOutlineStar> With Strongest Network Infrastructure</p>
                        <p className='flex mt-2'><AiOutlineStar className='text-2xl text-white mr-2 '></AiOutlineStar> With Best Security Possible</p>
                        <p className='flex mt-2'><AiOutlineStar className='text-2xl text-white mr-2 '></AiOutlineStar> With Highest Priority Given to Customer Support</p>
                    </div>
                </div>
            </div>
            <div
                data-aos="flip-up"
                data-aos-delay="500"
                data-aos-duration="1300"
                className="card w-full bg-red-100 shadow-xl rounded-none">
                <figure>

                </figure>
                <div className="card-body">
                    <div className='gird grid-cols-1'>
                        <div>
                            <div className='flex justify-between'>
                                <span>Infrastructure</span>
                                <span>93%</span>
                            </div>
                            <progress className="progress progress-primary" value="93" max="100"></progress>
                        </div>
                        <div>
                            <div className='flex justify-between'>
                                <span>Security</span>
                                <span>94%</span>
                            </div>
                            <progress className="progress progress-primary" value="94" max="100"></progress>
                        </div>
                        <div>
                            <div className='flex justify-between'>
                                <span>Uptime</span>
                                <span>97%</span>
                            </div>
                            <progress className="progress progress-primary" value="97" max="100"></progress>
                        </div>
                        <div>
                            <div className='flex justify-between'>
                                <span>Customer Support</span>
                                <span>98%</span>
                            </div>
                            <progress className="progress progress-primary" value="98" max="100"></progress>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    );
};

export default Work;