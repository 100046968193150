import React from "react";
import { AiOutlineHome } from "react-icons/ai";
import { MdCorporateFare, MdOutlineSecurity } from "react-icons/md";
import { SiNintendonetwork } from "react-icons/si";
import { FaIoxhost } from "react-icons/fa";
import { GiCctvCamera } from "react-icons/gi";
import { TbWorldDownload } from "react-icons/tb";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ServiceData from "../../Data/ServiceData/ServiceData";

const Services = () => {
  return (
    <section className="py-14">
      <h1 className="text-4xl font-bold text-black text-center">
        WHAT SERVICE WE PROVIDE ?
      </h1>
      <div className="container mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 p-4">
          {ServiceData.map((item, i) => (
            <div
              data-aos="flip-left"
              data-aos-delay="500"
              data-aos-duration="1300"
              className="card lg:max-w-lg bg-base-100 shadow-xl"
            >
              <figure className="px-4 pt-4">
                <span className="rounded-xl text-9xl text-blue-700">
                  {item.icons}
                </span>
              </figure>
              <div className="card-body items-center text-center">
                <h2 className="card-title">{item.title}</h2>
                <p>{item.details}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
