import React from "react";

const ChooseUs = () => {
  return (
    <section className=" bg-base-100 container mx-auto">
      <h1 className="text-4xl font-bold text-center text-black">
        WHY CHOOSE US?
      </h1>
      <div className="my-16">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 lg:mx-36 sm:mx-6">
          <div
            data-aos="zoom-out-right"
            data-aos-delay="300"
            data-aos-duration="1200"
            className="card lg:max-w-lg bg-base-100 shadow-xl"
          >
            <figure className="px-10 pt-10">
              <img
                src="https://i.ibb.co/QvH9w6n/reliablity.jpg"
                alt="Reliability"
                className="rounded"
              />
            </figure>
            <div className="card-body items-center text-center">
              <h2 className="card-title">UPTIME AND RELIABILITY</h2>
              <p>
                We have proactive monitoring systems in place to ensure the
                uptime & stability
              </p>
            </div>
          </div>

          <div
            data-aos="zoom-out-left"
            data-aos-delay="400"
            data-aos-duration="1200"
            className="card lg:max-w-lg bg-base-100 shadow-xl"
          >
            <figure className="px-10 pt-10">
              <img
                src="https://i.ibb.co/w79stmY/stream.jpg"
                alt="Stream"
                className="rounded"
              />
            </figure>
            <div className="card-body items-center text-center">
              <h2 className="card-title">MULTIPLE UPSTREAM</h2>
              <p>
                We maintain multiple upstream and redundant solutions for
                providing best up-time
              </p>
            </div>
          </div>

          <div
            data-aos="zoom-out-right"
            data-aos-delay="300"
            data-aos-duration="1200"
            className="card lg:max-w-lg bg-base-100 shadow-xl"
          >
            <figure className="px-10 pt-10">
              <img
                src="https://i.ibb.co/wpnFqdf/technology.jpg"
                className="rounded"
                alt="Support"
              />
            </figure>
            <div className="card-body items-center text-center">
              <h2 className="card-title">LATEST TECHNOLOGY</h2>
              <p>
                We have developed our servers for the best performance by
                equipping latest device
              </p>
            </div>
          </div>

          <div
            data-aos="zoom-out-left"
            data-aos-delay="400"
            data-aos-duration="1200"
            className="card lg:max-w-lg bg-base-100 shadow-xl"
          >
            <figure className="px-10 pt-10">
              <img
                src="https://i.ibb.co/Wkp9ysY/support.jpg"
                className="rounded"
                alt="Support"
              />
            </figure>
            <div className="card-body items-center text-center">
              <h2 className="card-title">CUSTOMER SUPPORT</h2>
              <p>
                We employ a staff of fully trained, friendly and professional
                agents that are available 24/7
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChooseUs;
