import Navbar from "./Pages/Shared/Navbar";
import NotFound from "./Pages/Shared/NotFound";
import { Routes, Route } from "react-router-dom";
import Footer from "./Pages/Shared/Footer";
import Packages from "./Pages/Package/Packages";
import FTPServer from "./Pages/FTPServer/FTPServer";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Contact from "./Pages/Contact";
import Home from "./Pages/Home/index";
import OurTeam from "./Pages/OurTeam";
import Payment from "./Pages/Payment";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <Navbar></Navbar>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="packages" element={<Packages />}></Route>
        <Route path="contact" element={<Contact />}></Route>
        <Route path="team" element={<OurTeam />}></Route>
        <Route path="payment" element={<Payment />}></Route>
        <Route path="server" element={<FTPServer />}></Route>
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
      <Footer></Footer>
    </>
  );
}

export default App;
