import React, { useState } from "react";
import CustomLink from "../CustomLink/CustomLink";

const Navbar = () => {
  const navItemLeft = (
    <>
      <li>
        <CustomLink
          to="/"
          aria-label="Home"
          title="Home"
          className="font-medium tracking-wide text-gray-100 transition-colors duration-200 hover:text-teal-accent-400"
        >
          Home
        </CustomLink>
      </li>
      <li>
        <CustomLink
          to="/packages"
          aria-label="Packages"
          title="Packages"
          className="font-medium tracking-wide text-gray-100 transition-colors duration-200 hover:text-teal-accent-400"
        >
          Packages
        </CustomLink>
      </li>
      <li>
        <CustomLink
          to="/payment"
          aria-label="Our product"
          title="Our product"
          className="font-medium tracking-wide text-gray-100 transition-colors duration-200 hover:text-teal-accent-400"
        >
          Pay Bill
        </CustomLink>
      </li>
    </>
  );

  const navItemRight = (
    <>
      <li>
        <CustomLink
          to="/server"
          aria-label="Our product"
          title="Our product"
          className="font-medium tracking-wide text-gray-100 transition-colors duration-200 hover:text-teal-accent-400"
        >
          FTP Server
        </CustomLink>
      </li>
      <li>
        <CustomLink
          to="/contact"
          aria-label="Our product"
          title="Our product"
          className="font-medium tracking-wide text-gray-100 transition-colors duration-200 hover:text-teal-accent-400"
        >
          Contact Us
        </CustomLink>
      </li>
      <li>
        <CustomLink
          to="/team"
          aria-label="About us"
          title="About us"
          className="font-medium tracking-wide text-gray-100 transition-colors duration-200 hover:text-teal-accent-400"
        >
          Our Team
        </CustomLink>
      </li>
    </>
  );

  const navItemResponsive = (
    <>
      <li>
        <CustomLink
          to="/"
          aria-label="Home"
          title="Home"
          className="font-medium tracking-wide text-gray-100 transition-colors duration-200 hover:text-teal-accent-400"
        >
          Home
        </CustomLink>
      </li>
      <li>
        <CustomLink
          to="/packages"
          aria-label="Packages"
          title="Packages"
          className="font-medium tracking-wide text-gray-100 transition-colors duration-200 hover:text-teal-accent-400"
        >
          Packages
        </CustomLink>
      </li>
      <li>
        <CustomLink
          to="/payment"
          aria-label="Our product"
          title="Our product"
          className="font-medium tracking-wide text-gray-100 transition-colors duration-200 hover:text-teal-accent-400"
        >
          Pay Bill
        </CustomLink>
      </li>
      <li>
        <CustomLink
          to="/server"
          aria-label="Our product"
          title="Our product"
          className="font-medium tracking-wide text-gray-100 transition-colors duration-200 hover:text-teal-accent-400"
        >
          FTP Server
        </CustomLink>
      </li>
      <li>
        <CustomLink
          to="/contact"
          aria-label="Our product"
          title="Our product"
          className="font-medium tracking-wide text-gray-100 transition-colors duration-200 hover:text-teal-accent-400"
        >
          Contact Us
        </CustomLink>
      </li>
      <li>
        <CustomLink
          to="/team"
          aria-label="About us"
          title="About us"
          className="font-medium tracking-wide text-gray-100 transition-colors duration-200 hover:text-teal-accent-400"
        >
          Our Team
        </CustomLink>
      </li>
    </>
  );

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="bg-gray-900 sticky top-0 z-50">
      <div className="px-4 py-6 mx-auto lg:py-6 sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
        <div className="relative flex items-center justify-between lg:justify-center lg:space-x-16">
          <ul className="flex items-center hidden space-x-8 lg:flex">
            {navItemLeft}
          </ul>
          <CustomLink
            to="/"
            aria-label="Company"
            title="Company"
            className="inline-flex items-center"
          >
            <svg
              className="w-8 text-teal-accent-400"
              viewBox="0 0 24 24"
              strokeLinejoin="round"
              strokeWidth="2"
              strokeLinecap="round"
              strokeMiterlimit="10"
              stroke="currentColor"
              fill="none"
            >
              {/* <rect x="3" y="1" width="7" height="12" /> */}
              <rect x="3" y="17" width="7" height="6" />
              <rect x="14" y="1" width="7" height="6" />
              <rect x="14" y="11" width="7" height="12" />
            </svg>
            <span className="ml-2 text-xl font-bold tracking-wide text-gray-100 uppercase">
              JP Network BD
            </span>
          </CustomLink>
          <ul className="flex items-center hidden space-x-8 lg:flex">
            {navItemRight}
          </ul>
          <div className="lg:hidden">
            <button
              aria-label="Open Menu"
              title="Open Menu"
              className="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline"
              onClick={() => setIsMenuOpen(true)}
            >
              <svg className="w-5 text-gray-200" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
                />
                <path
                  fill="currentColor"
                  d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
                />
                <path
                  fill="currentColor"
                  d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
                />
              </svg>
            </button>
            {isMenuOpen && (
              <div className="absolute top-0 left-0 w-full z-10">
                <div className="p-5 bg-gray-700 border rounded shadow-sm">
                  <div className="flex items-center justify-between mb-4">
                    <div>
                      <CustomLink
                        to="/"
                        aria-label="Home"
                        title="Home"
                        className="inline-flex items-center"
                      >
                        <svg
                          className="w-8 text-deep-purple-accent-400"
                          viewBox="0 0 24 24"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeMiterlimit="10"
                          stroke="currentColor"
                          fill="none"
                        >
                          {/* <rect x="3" y="1" width="7" height="12" /> */}
                          <rect x="3" y="17" width="7" height="6" />
                          <rect x="14" y="1" width="7" height="6" />
                          <rect x="14" y="11" width="7" height="12" />
                        </svg>
                        <span className="ml-2 text-xl font-bold tracking-wide text-gray-100 uppercase">
                          JP Network BD
                        </span>
                      </CustomLink>
                    </div>
                    <div>
                      <button
                        aria-label="Close Menu"
                        title="Close Menu"
                        className="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        <svg className="w-5 text-gray-600" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <nav>
                    <ul className="space-y-4">{navItemResponsive}</ul>
                  </nav>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
