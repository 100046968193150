import React from "react";
import { Link } from "react-router-dom";

const TeamBanner = () => {
  return (
    <>
      <div
        className="hero hero-main"
        style={{
          backgroundImage: "url(https://i.ibb.co/HG07b7N/team-banner.jpg)",
          objectFit: "cover",
        }}
      >
        <div className="hero-overlay bg-opacity-20"></div>
        <div className="hero-content text-center text-neutral-content">
          <div className="max-w-lg">
            <h1
              data-aos="fade-down"
              data-aos-delay="400"
              data-aos-duration="1300"
              className="mb-5 text-5xl font-bold capitalize"
            >
              Welcome to JP Network BD{" "}
              <span className="text-red-600">TEAM</span>{" "}
            </h1>
            <span
              data-aos="fade-up"
              data-aos-delay="600"
              data-aos-duration="1300"
              className="mb-5 text-2xl font-bold"
            >
              <Link to="/">Home</Link> | Our Team
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamBanner;
