import React from 'react';
import ReactTypingEffect from 'react-typing-effect';
import './SendEmail.css';

const SendEmail = () => {
    return (
        <section className='sendEmail'>

            <div className="hero min-h-screen" >
                <div className="hero-overlay bg-opacity-30"></div>
                <div className="p-0">
                    <h1 className='text-white text-5xl font-bold p-10'><span className='text-black'>Check ability</span> to connect our services in your area</h1>

                    <div className="form-control">
                        <label className="input-group-lg text-center">
                            <span className='btn lg:btn-wide bg-black text-white'>Send Email</span>
                            <input type="email" placeholder="info@site.com" className="input lg:w-2/3" />
                        </label>
                    </div>

                </div>
            </div>
        </section>
    );
};

export default SendEmail;