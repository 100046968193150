import { AiOutlineHome } from "react-icons/ai";
import { MdCorporateFare, MdOutlineSecurity } from "react-icons/md";
import { SiNintendonetwork } from "react-icons/si";
import { FaIoxhost } from "react-icons/fa";
import { GiCctvCamera } from "react-icons/gi";
import { TbWorldDownload } from "react-icons/tb";

const ServiceData = [
  {
    id: 1,
    title: "Home Broadband Internet",
    details:
      "JP Internet is providing one of the fastest broadband internet and network solutions throughout Dhaka, reliable for both gamers and regular users.",
    icons: <AiOutlineHome />,
  },
  {
    id: 2,
    title: "Corporate/SME Internet",
    details:
      "Offering dedicated internet connection with various network solution ensuring network stability for our Corporate and SME consumers. Along with a support manager for 24/7 response.",
    icons: <MdCorporateFare />,
  },
  {
    id: 3,
    title: "Network Solution",
    details:
      "JP Internet provides both LAN & WAN networks solutions. With the help of our experienced network engineers, we ensure the most efficient Network solutions for our clients.",
    icons: <SiNintendonetwork />,
  },
  {
    id: 4,
    title: "Network Security",
    details:
      "JP Internet provides VPN, Antivirus, CCTV IP surveillance solutions ensuring top of the line security to your end.",
    icons: <MdOutlineSecurity />,
  },
  {
    id: 5,
    title: "Dedicated Server Hosting",
    details:
      "Get a dedicated server hosted on your end to complete your daily needs with efficient network routing and configuration.",
    icons: <FaIoxhost />,
  },
  {
    id: 6,
    title: "CCTV System Solutions",
    details:
      "JP Internet provides high-end CCTV and IP surveillance solutions.",
    icons: <GiCctvCamera />,
  },
  {
    id: 7,
    title: "Dedicated Internet",
    details:
      "Dedicated high-speed internet connectivity with multiple upstream support and multiple backup links to ensure 99.9% uptime.",
    icons: <TbWorldDownload />,
  },
];

export default ServiceData;
