import React from "react";
import { Link } from "react-router-dom";
import ReactTypingEffect from "react-typing-effect";

const Banner = () => {
  return (
    <>
      <div
        className="hero hero-main"
        style={{
          backgroundImage: "url(https://i.ibb.co/kK90fLc/banner2.jpg)",
          objectFit: "cover",
        }}
      >
        <div className="hero-overlay bg-opacity-50"></div>
        <div className="hero-content text-center text-neutral-content">
          <div className="max-w-lg  bg-opacity-60">
            <h1
              data-aos="fade-down"
              data-aos-delay="400"
              data-aos-duration="1300"
              className="mb-5 text-5xl font-bold capitalize text-white"
            >
              <span className="text-red-600">Stay Tune </span>
              <ReactTypingEffect className="text-white" text={["with Us!"]} />
            </h1>
            <p
              data-aos="fade-down"
              data-aos-delay="400"
              data-aos-duration="1300"
              className="mb-5"
            >
              <span className="mb-5 text-2xl font-bold">
                <Link to="/">Home</Link> | Contact
              </span>
              <p className="mb-5 text-xl font-bold text-white mt-4">
                Phone: 01818883222, 01681553230
              </p>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
